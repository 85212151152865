body {
  font-family: 'Roboto', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animation for title */
.fade-in-title {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 0.8s ease-in-out forwards;
}

/* Animation for subtitle */
.fade-in-subtitle {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-in-out forwards;
  animation-delay: 0.3s; /* Adds a slight delay for a staggered effect */
}

/* Keyframes for fade-in up effect */
@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Animation for divider line */
.fade-in-divider {
  opacity: 0;
  transform: scaleX(0);
  animation: fadeInDivider 0.8s ease-in-out forwards;
  animation-delay: 0.2s; /* Optional delay for a staggered effect */
}

/* Keyframes for fade-in divider effect */
@keyframes fadeInDivider {
  from {
      opacity: 0;
      transform: scaleX(0);
  }
  to {
      opacity: 1;
      transform: scaleX(1);
  }
}

/* Animation for "View My Work" button */
.fade-in-button {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInButton 0.8s ease-in-out forwards;
  animation-delay: 0.4s; /* Slight delay after subtitle animation */
}

/* Keyframes for button animation */
@keyframes fadeInButton {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

#background {
  z-index: -1;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}

.lazy-background {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.lazy-background.loaded {
  opacity: 1;
}


/* Splash screen styling */
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000020; /* Match the main background color */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.5s ease;
}

/* Loading dots animation */
.loading-animation {
  display: flex;
  gap: 8px;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: #F0F0F0;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

/* Bouncing animation */
@keyframes bounce {
  from {
      transform: translateY(0);
  }
  to {
      transform: translateY(-15px);
  }
}

/* Fade out effect for splash screen */
.hidden .splash-screen {
  opacity: 0;
  pointer-events: none;
}

/* Fade-in animation for main content */
.fade-in {
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
